import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b80d60c2 = () => interopDefault(import('../pages/campaign-template/index.vue' /* webpackChunkName: "pages/campaign-template/index" */))
const _50dc7ddc = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _c426224a = () => interopDefault(import('../pages/header/index.vue' /* webpackChunkName: "pages/header/index" */))
const _14735ab4 = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _7e3df638 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _221881b5 = () => interopDefault(import('../pages/modal/index.vue' /* webpackChunkName: "pages/modal/index" */))
const _ad5d20bc = () => interopDefault(import('../pages/news-post/index.vue' /* webpackChunkName: "pages/news-post/index" */))
const _b96cd5c6 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _0ddb0640 = () => interopDefault(import('../pages/page-template/index.vue' /* webpackChunkName: "pages/page-template/index" */))
const _1838add0 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _31c1d658 = () => interopDefault(import('../pages/scene/index.vue' /* webpackChunkName: "pages/scene/index" */))
const _48ff3f19 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _505b245c = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _614ca25a = () => interopDefault(import('../pages/work-activity/index.vue' /* webpackChunkName: "pages/work-activity/index" */))
const _165a0d48 = () => interopDefault(import('../pages/news-post/view/index.vue' /* webpackChunkName: "pages/news-post/view/index" */))
const _599eb960 = () => interopDefault(import('../pages/work-activity/map/index.vue' /* webpackChunkName: "pages/work-activity/map/index" */))
const _4a3ea4b7 = () => interopDefault(import('../pages/work-activity/view/index.vue' /* webpackChunkName: "pages/work-activity/view/index" */))
const _3f86a09e = () => interopDefault(import('../pages/campaigns/news-post/_id.vue' /* webpackChunkName: "pages/campaigns/news-post/_id" */))
const _e368d870 = () => interopDefault(import('../pages/work-activity/map/_id.vue' /* webpackChunkName: "pages/work-activity/map/_id" */))
const _3a83ff93 = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _56627260 = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "pages/contents/_id" */))
const _17765c57 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign-template",
    component: _b80d60c2,
    name: "campaign-template"
  }, {
    path: "/form",
    component: _50dc7ddc,
    name: "form"
  }, {
    path: "/header",
    component: _c426224a,
    name: "header"
  }, {
    path: "/healthcheck",
    component: _14735ab4,
    name: "healthcheck"
  }, {
    path: "/map",
    component: _7e3df638,
    name: "map"
  }, {
    path: "/modal",
    component: _221881b5,
    name: "modal"
  }, {
    path: "/news-post",
    component: _ad5d20bc,
    name: "news-post"
  }, {
    path: "/page",
    component: _b96cd5c6,
    name: "page"
  }, {
    path: "/page-template",
    component: _0ddb0640,
    name: "page-template"
  }, {
    path: "/preview",
    component: _1838add0,
    name: "preview"
  }, {
    path: "/scene",
    component: _31c1d658,
    name: "scene"
  }, {
    path: "/unsubscribe",
    component: _48ff3f19,
    name: "unsubscribe"
  }, {
    path: "/widget",
    component: _505b245c,
    name: "widget"
  }, {
    path: "/work-activity",
    component: _614ca25a,
    name: "work-activity"
  }, {
    path: "/news-post/view",
    component: _165a0d48,
    name: "news-post-view"
  }, {
    path: "/work-activity/map",
    component: _599eb960,
    name: "work-activity-map"
  }, {
    path: "/work-activity/view",
    component: _4a3ea4b7,
    name: "work-activity-view"
  }, {
    path: "/campaigns/news-post/:id?",
    component: _3f86a09e,
    name: "campaigns-news-post-id"
  }, {
    path: "/work-activity/map/:id",
    component: _e368d870,
    name: "work-activity-map-id"
  }, {
    path: "/campaigns/:id?",
    component: _3a83ff93,
    name: "campaigns-id"
  }, {
    path: "/contents/:id?",
    component: _56627260,
    name: "contents-id"
  }, {
    path: "/",
    component: _17765c57,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
